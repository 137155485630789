// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

var jQuery = require("jquery");

// import jQuery from "jquery";
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

require("bootstrap");
require("tinymce");
require("tinymce/plugins/link");
require("tinymce/plugins/lists");
require("tinymce/plugins/autolink");
require("tinymce/plugins/code");
require("tinymce/themes/silver/theme");
require("tinymce/icons/default/icons");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

let headerHeight = 56,
    logoHeight = 400,
    scrollTo = 500,
    initialLogoY = 30;
    if(window.innerHeight > window.innerWidth){
      initialLogoY = 15;
    }

window.addEventListener("scroll", event => {
  if ($("body").hasClass("welcome") && $("body").hasClass("index")) {
    let yPos = window.scrollY,
        yPer = (yPos / scrollTo),
        offsetXpx = document.getElementsByClassName("logo-placeholder")[0].offsetLeft + 50;
        offsetX = offsetXpx*100/$("body").width();

    if (yPer > 1) { yPer = 1; }

    let logoPosX = (-1*(yPer*50)+50),
        logoPosY = (-1*(yPer*initialLogoY)+initialLogoY),
        logoSize = ((headerHeight*yPer)-(logoHeight*yPer)+logoHeight);

    let $logo = $(".logo");

    let xResult = logoPosX+"%"
    if (logoPosX < offsetX) {
      xResult = offsetXpx+"px"
    }
    $logo.css("left", xResult);
    if (logoPosY < 2) {
      logoPosY = 2
    }
    if (logoSize < 100) {
      $logo.css("height", "100px");
    } else {
      $logo.css("height", logoSize);
    }
    if (yPer === 1) {
      $logo.css("top", "15px");
      $logo.css("left", offsetXpx);
      $logo.css("height", "100px");
    } else {
      $logo.css("top", logoPosY + "%");
    }
  }
});

$(document).on("click", "[data-form-prepend]", function(e) {
  var obj = $($(this).attr("data-form-prepend"));
  obj.find("input, select, textarea").each(function() {
    $(this).attr("name", function() {
      return $(this)
        .attr("name")
        .replace("new_record", new Date().getTime());
    });
  });
  obj.insertBefore(this);
  return false;
});

tinymce.init({
  selector: ".tinymce",
  plugins: [
    'link',
    'lists',
    'autolink',
    'code',
  ]
});
